<template>
  <div>
    <div
      v-if="loading || parentLoading"
      style="
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.8);
      "
    >
      <b-spinner
        v-if="loading || parentLoading"
        label="Spinning"
        variant="primary"
      ></b-spinner>
    </div>

    <b-card no-body class="m-2" v-show="!loading && !parentLoading">
      <b-card-header class="pb-50 text-capitalize">
        <h5>{{ $t("filter") }}</h5>
      </b-card-header>

      <b-card-body>
        <b-row>
          <b-col
            v-if="filters.tenant"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>Tenant</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="tenantFilter"
              :options="tenantOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (tenantFilter = val)"
            />
          </b-col>
          <b-col
            v-if="filters.center"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>{{ $t("omniview_reseller.center") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="centerFilter"
              :options="centerOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (centerFilter = val)"
            />
          </b-col>
          <b-col
            v-if="filters.sector"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>{{ $t("omniview_reseller.sector") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="sectorFilter"
              :options="sectorOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (sectorFilter = val)"
            />
          </b-col>
          <b-col
            v-if="filters.units"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>{{ $t("omniview_reseller.facilities") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="unitsFilter"
              :options="unitsOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (unitsFilter = val)"
            />
          </b-col>
          <b-col
            v-if="filters.online"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>Online</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="onlineFilter"
              :options="onlineOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (onlineFilter = val)"
            />
          </b-col>
          <b-col
            v-if="filters.assing"
            cols="3"
            md="3"
            class="mb-md-0 mb-2 text-capitalize"
          >
            <label>{{ $t("camera_proxy.assigned") }}</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="assignedFilterValue"
              :options="assignmentThisOmniaResellerOptions"
              class="w-100"
              :reduce="(val) => val.value"
              @input="(val) => (assignedFilterValue = val)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Toastification loading content export -->
    <toastification-loading-content ref="loadingToast" />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body v-show="!loading && !parentLoading">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }} </label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search') + '...'"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>
        <template #head(idOmniaReseller)="data">
          <b-form-checkbox
            v-model="AssignmentCheckbox"
            value="1"
            unchecked-value="0"
            @change="selectAllItems"
            :disabled="loading"
          ></b-form-checkbox>
        </template>

        <template #cell(idOmniaReseller)="data">
          <b-form-checkbox
            :id="'checkbox-' + data.item.id"
            v-model="data.item.assing"
            :name="'checkbox-' + data.item.id"
            value="1"
            unchecked-value="0"
            @change="setCamera(data.item)"
            :disabled="loading"
          ></b-form-checkbox>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>

            <span class="text-muted text-capitalize ml-5"
              >{{ $t("camera_proxy.assigned") }} {{ assingDevices.count }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <Pagination
              :totalList="totalList"
              :currentPage="currentPage"
              @updatePagination="update"
              :perPage="perPage"
            >
            </Pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, watch, onMounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import useList from "./useList";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import Pagination from "@/components/Pagination.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ToastificationLoadingContent from "@core/components/toastification/ToastificationLoadingContent.vue";

export default {
  components: {
    Breadcrumb,
    ToastificationLoadingContent,
    Pagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
  },
  props: {
    config: {
      //recieves id_user or id_omniviewreseller, and filters
      type: Object,
      default: () => ({}),
    },
    assingDevices: {
      //the assigned devices just the ids
     type: [Object, Array],
      default: () => ({}),
    },
    isParentLoading: Boolean,
  },
  setup(props, { emit }) {
    const filters = ref({});

    const assing = ref("");

    const selectAllItems = () => {
      getAllId();
    };

    const parentLoading = ref(false);

    const getAllId = async () => {
      //This method is for getting proxyIds and send it to parent updateStatus
      if (assignedFilterValue.value == 1) {
        let ids = assingDevicesComponent.value.cameraProxyIds;
        //we check if the devices assigned are filtered
        emit("updateStatus", {
          multiple: true,
          ids: assingDevicesComponent.value.cameraProxyIds, //send just all devices assigned.
          status: AssignmentCheckbox.value,
          page: currentPage.value,
          perPage: perPage.value,
          searchQuery: searchQuery.value
        });
      } else {
        try {
          emit("updateStatus", {
            multiple: true,
            id_unit: unitsFilter.value,
            id_sector: sectorFilter.value,
            id_center: centerFilter.value,
            online: onlineFilter.value,
            id_tenant: tenantFilter.value,
            status: AssignmentCheckbox.value,
            searchQuery: searchQuery.value
          });
        } catch (err) {
          console.log(err);
        }
      }
    };

    const setCamera = (item) => {
      emit("updateStatus", {
        id: item.id,
        status: item.assing,
        page: currentPage.value,
        perPage: perPage.value,
      });
    };
    //start pagination
    const update = (data) => {
      currentPage.value = data;
    };
    //end pagination

    // start export
    const loadingToast = ref(null);
    const action = ref("list");
    const { t } = useI18nUtils();
    const trans = {
      "messages.export.download": t("messages.export.download"),
      "messages.export.Generating_file": t("messages.export.Generating_file"),
      "messages.error.server_error": t("messages.error.server_error"),
      "messages.delete.title": t("messages.delete.title"),
      "messages.delete.body": t("messages.delete.body"),
      "actions.delete": t("actions.delete"),
      "actions.cancel": t("actions.cancel"),
    };

    const {
      assignmentThisOmniaReseller,
      assignmentThisOmniaResellerOptions,
      assignment,
      tenantFilter,
      tenantOptions,
      onlineFilter,
      onlineOptions,
      centerFilter,
      centerOptions,
      timezoneOptions,
      sectorFilter,
      sectorOptions,
      unitsFilter,
      unitsOptions,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      idOmniaReseller,
      configComponet,
      assingDevicesComponent,
      AssignmentCheckbox,
      assignedFilterValue,
      loading
    } = useList(props.config);

    onMounted(() => {
      configComponet.value = props.config;

      assingDevicesComponent.value = props.assingDevices;
      filters.value = props.config.filters;
    });

    watch(
      () => props.assingDevices,
      () => {
        assingDevicesComponent.value = props.assingDevices;
      }
    );

    watch(
      () => props.isParentLoading,
      (newVal) =>{
        parentLoading.value = newVal
      }
    )

    return {
      filters,
      assingDevicesComponent,
      configComponet,
      assignedFilterValue,
      assignmentThisOmniaReseller,
      assignmentThisOmniaResellerOptions,
      AssignmentCheckbox,
      assignment,
      selectAllItems,
      assing,
      tenantFilter,
      tenantOptions,
      onlineFilter,
      onlineOptions,
      centerFilter,
      centerOptions,
      sectorFilter,
      sectorOptions,
      timezoneOptions,
      unitsFilter,
      unitsOptions,
      loadingToast,
      action,
      getAllId,
      update,
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      idOmniaReseller,
      setCamera,
      loading,
      parentLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
